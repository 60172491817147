<template>
  <TopPanel />

  <div id="main-container" class="container-xl container-fluid p-0 margin-page-bottom">
    <div class="row">
      <div class="col">
        <ContentDoc />
      </div>
    </div>
  </div>
</template>
